import React, { useEffect, useState } from 'react';
import Header from "../components/header";
import Footer from "../components/footer";
import Scroll from '../components/scroll'
import "@fontsource/poppins/100.css";
import "@fontsource/poppins/200.css";
import "@fontsource/poppins/600.css"
import "@fontsource/dm-sans/700.css";
import "@fontsource/dm-sans/400.css";
import { Helmet } from "react-helmet";
import i18next from "i18next";
import antoine from "../images/antoine.webp";
import matthieu from "../images/matthieu.webp";

const people = [
    {
        name: 'Antoine Lesire',
        role: 'CEO',
        imageUrl: antoine,
        linkedinUrl: 'https://www.linkedin.com/in/-b6234213b/',
    },
    {
        name: 'Matthieu van der Vleugel',
        role: 'CCO',
        imageUrl: matthieu,
        linkedinUrl: 'https://www.linkedin.com/in/matthieu-van-der-vleugel-2386771b7/',
    },
]

const TeamPage = () => {

    const [isLocalStorageAvailable, setLocalStorageAvailable] = useState(false);
  const [language, setLanguage] = useState('en');  // default language

  useEffect(() => {
    if (typeof localStorage !== 'undefined') {
      setLocalStorageAvailable(true);
      const lang = localStorage.getItem('lang') || 'en';  // default to 'en' if not set
      setLanguage(lang);
    }
  }, []);
    
    return (
        <div className="bg-white">
            <Scroll showBelow={250} />
            <Helmet htmlAttributes={{ lang: language }}>
                <meta charSet="utf-8" />
                <meta name="description" content="Rejoice is a dating app designed to help you find your soulmate, new friends or someone to hookup with based on your compatibility" />
                <meta name="keywords" content="Rejoice, dating, app, new, big5, concept, young, ipq, meet, people, girls" />
                <meta name="author" content="Rejoice" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <title>{i18next.t('team.header.title')}</title>
            </Helmet>
            <Header />

            <div className="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24">
                <div className="space-y-12">
                    <div className="space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none">
                        <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">{i18next.t('team.title')}</h2>
                        <p className="text-xl text-gray-500">
                        {i18next.t('team.caption')}                        </p>
                    </div>
                    <ul className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:grid-cols-3 lg:gap-x-8">
                        {people.map((person,i) => (
                            <li key={i}>
                                <div className="space-y-4">
                                    <div className="aspect-w-4 aspect-h-4">
                                        <img className="object-cover shadow-lg rounded-lg" src={person.imageUrl} alt="" />
                                    </div>

                                    <div className="space-y-2">
                                        <div className="text-lg leading-6 font-medium space-y-1">
                                            <h3>{person.name} </h3>
                                            <p className="text-indigo-600">{person.role}</p>
                                        </div>
                                        <ul className="flex space-x-5">
                                            <li>
                                                <a href={person.linkedinUrl} className="text-gray-400 hover:text-gray-500">
                                                    <span className="sr-only">LinkedIn</span>
                                                    <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                                                        <path
                                                            fillRule="evenodd"
                                                            d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                                                            clipRule="evenodd"
                                                        />
                                                    </svg>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <Footer />
        </div>
    );
};
export default TeamPage;
